const RANGE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const MIN_DEFAULT_VALUE = 1;
const MAX_DEFAULT_VALUE = 10;
const REQUIRE_MANUAL_INPUT = "10+";

document.addEventListener('DOMContentLoaded', initQtyPickers);

export function initQtyPickers() {
  [...document.querySelectorAll('[data-qty-picker]')].map(picker => {
    const trigger = picker.querySelector('button');
    const input = picker.querySelector('input');
    const min = input.min || MIN_DEFAULT_VALUE;
    const max = input.max || null;

    initQtyPickerOptions(picker, min, max);
    trigger.addEventListener('click', () => showPickerOptions(picker));
  });
}

export function initQtyPickerOptions(picker, min, max) {
  reset(picker, min, max);
  getQuantityOptions(min, max).map(option => {
    picker.querySelector('ul').append(option);
    option.querySelector('button').addEventListener('click', event => {
      if (!document.querySelector('[data-sc-cart-page]')) {
        event.preventDefault();
      }
      if (event.currentTarget.value === REQUIRE_MANUAL_INPUT) {
        event.preventDefault();
        setQuantity(MAX_DEFAULT_VALUE, picker);
        hideOptions(picker);
        showManualInput(picker);
      } else {
        setQuantity(event.currentTarget.value, picker);
      }
    });
  });
}

export function showPickerOptions(picker) {
  const pickers = document.querySelectorAll('[data-qty-picker]');
  const list = picker.querySelector('ul');

  hideOtherPickers(picker, pickers);
  list.classList.add('is-active');
  list.setAttribute('aria-hidden', false);
  document.addEventListener('click', e => closeIfClickElsewhere(e, picker));
}

export function setQuantity(value, picker) {
  const input = picker.querySelector('input');
  const label = picker.querySelector('[data-qty-picker-value]');

  hideOptions(picker);
  input.value = value;
  input.dispatchEvent(new Event('change'));
  label.innerText = value;
}

function getQuantityOptions(min, max) {
  const maxQty = max === "Infinity" ? MAX_DEFAULT_VALUE : max;
  const range = RANGE.slice(min, parseInt(maxQty || MAX_DEFAULT_VALUE) + 1);

  return range.map(qty => {
    const option = document.createElement('li');
    const button = document.createElement('button');
    const noMax = max >= MAX_DEFAULT_VALUE || !max;

    if (qty === MAX_DEFAULT_VALUE && noMax) {
      button.value = REQUIRE_MANUAL_INPUT;
      button.innerText = REQUIRE_MANUAL_INPUT;
    } else {
      button.value = qty;
      button.innerText = qty;
    }

    option.append(button);
    return option;
  });
}

function reset(picker, min, max) {
  const qty = picker.querySelector('[data-qty-picker-value]');
  const value = parseInt(qty.innerText);

  if (value > parseInt(max) || value < parseInt(min)) {
    picker.querySelector('[data-qty-picker-value]').innerText = min;
  }
  picker.querySelector('[data-qty-picker-trigger]').classList.remove('is-hidden');
  picker.querySelector('input').classList.remove('is-active');
  picker.querySelector('ul').innerHTML = '';
}

function hideOptions(picker) {
  const list = picker.querySelector('ul');

  list.classList.remove('is-active');
  list.setAttribute('aria-hidden', true);
}

function hideOtherPickers(activePicker, pickers) {
  [...pickers].map(picker => {
    if (picker !== activePicker) hideOptions(picker);
  });
}

function showManualInput(picker) {
  const input = picker.querySelector('input');
  const trigger = picker.querySelector('[data-qty-picker-trigger]');
  const update = picker.querySelector('[data-qty-picker-update]');

  if (update) update.style.display = 'block';
  trigger.classList.add('is-hidden');
  input.classList.add('is-active');

  input.type = 'text';
  input.setSelectionRange(input.value.length, input.value.length);
  input.focus();
  input.select();
  // reverting to number type without time doesn't seem to be working at all
  // however setting timeout works
  setTimeout(() => { input.type = 'number'; }, 1);
}

function closeIfClickElsewhere(event, picker) {
  if (!picker.contains(event.target)) hideOptions(picker);
}
